<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.reviews.index'}">Back</v-btn>
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-switch v-model="review.status"
                                        :label="`Status: ${review.status ? ' Approve' : ' Ban'}`"
                                        :color="review.status ? 'teal accent-3' : 'red darken-3'"
                            >
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveReview">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import Review from "../../../models/Review";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";


export default {
    name: "comments-show",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            review: {},
            formValid: true,
            labelSwitch:''
        }
    },
    methods: {
        async handleSaveReview() {
            this.formValid = this.$refs.form.validate()
                
            if (this.formValid) {
                const review = new Review(this.review);
                this.review = await review.save()
                await this.$router.push({name: 'admin.reviews.show', params: {id: this.review.id}}).catch(()=>{});
            }
        },
    },
    async mounted() {
        const reviewId = this.$route.params.id
        if (reviewId) {
            this.review = await Review.find(reviewId);
    
            if(this.review.status == 0){
                this.labelSwitch = 'Banned'
            }else if (this.review.status == 1){
                 this.labelSwitch = 'Approved'
            }
        }
    },
}
</script>

<style scoped>

</style>
